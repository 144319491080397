import React from 'react';
import { Redirect } from '@reach/router';
import { graphql, Link } from 'gatsby';
import {
  AboutBlock,
  CareersBlock,
  CaseStudyBlock,
  Hero,
  ServiceBlock,
  LibraryBlock,
  Ram,
} from '../ui';
import htmlSerializer from '../prismic/htmlSerializer';
import Layout from '../layout/primary';

export const query = graphql`
  {
    prismic {
      content: allHome_page__generals {
        edges {
          node {
            meta_title
            meta_description
            hero_header
            hero_copy
            hero_image
            market_challenge_text
            services_eyebrow
            services_image
            services_header
            services_copy
            about_eyebrow
            about_header
            about_copy
            careers_eyebrow
            careers_header
            careers_copy
            careers_background
            careers_image
            library_eyebrow
            library_header
            library_copy
            library_challenge_text
            featured_project {
              ... on PRISMIC_Publication {
                _meta {
                  uid
                }
                title
                summary
                image
                body {
                  ... on PRISMIC_PublicationBodyProject_proof_data {
                    primary {
                      stat_1_value
                      stat_1_context
                      stat_1_source
                      stat_2_value
                      stat_2_context
                      stat_2_source
                      stat_3_value
                      stat_3_context
                      stat_3_source
                    }
                  }
                }
              }
            }
            publications {
              publication {
                ... on PRISMIC_Publication {
                  _meta {
                    id
                    uid
                  }
                  title
                  type
                  image
                  summary
                }
              }
            }
          }
        }
      }
      markets: allMarkets(sortBy: priority_ASC) {
        edges {
          node {
            name
            thumbnail
            _meta {
              uid
            }
          }
        }
      }
    }
  }
`;

export default function({ data }) {
  const selectedMarket = typeof window !== 'undefined' && localStorage.getItem('selectedMarket');

  if (selectedMarket) return <Redirect to={`/${selectedMarket}/`} noThrow />;

  // Set content
  const content = data.prismic.content.edges[0].node;
  const stats = [
    {
      highlightsProps: content.featured_project.body[0].primary.stat_1_value,
      captionProps: content.featured_project.body[0].primary.stat_1_context,
    },
    {
      highlightsProps: content.featured_project.body[0].primary.stat_2_value,
      captionProps: content.featured_project.body[0].primary.stat_2_context,
    },
    {
      highlightsProps: content.featured_project.body[0].primary.stat_3_value,
      captionProps: content.featured_project.body[0].primary.stat_3_context,
    },
  ];

  const libraryCards = content.publications.slice(0, 3).map(({ publication }) => {
    const excerpt = publication.summary ? publication.summary[0].text : publication.text[0].text;

    return {
      id: publication._meta.id,
      category: publication.type,
      title: publication.title,
      to: `/library/${publication._meta.uid}`,
      image: publication.image.thumbnail,
      excerpt: excerpt.length > 140 ? `${excerpt.substring(0, 140)}…` : excerpt,
      linkText: 'Read more',
    };
  });

  return (
    <Layout
      title={content.meta_title}
      description={content.meta_description}
      image={content.hero_image && content.hero_image.url}
    >
      <Hero
        Link={Link}
        heroHeader={content.hero_header}
        heroCopy={content.hero_copy[0].text}
        imageDesk={content.hero_image.url}
        imageMobile={content.hero_image.url}
        markets={data.prismic.markets.edges}
        marketSelectorBody={content.market_challenge_text[0].text}
      />
      <ServiceBlock
        Link={Link}
        image={content.services_image}
        eyebrowProps={{
          color: 'accent-2',
          text: content.services_eyebrow,
        }}
        titleProps={{
          text: content.services_header,
        }}
        bodyProps={{
          text: content.services_copy[0].text,
        }}
        ctaButtonProps={{
          to: '/services',
          text: 'Our Services',
        }}
      />
      <CaseStudyBlock
        Link={Link}
        stats={stats}
        image={content.featured_project.image}
        eyebrowProps={{
          color: 'accent-2',
          text: 'Featured Project',
        }}
        titleProps={{
          text: content.featured_project.title,
          color: 'white',
        }}
        bodyProps={{
          text: content.featured_project.summary,
          color: 'white',
        }}
        ctaButtonProps={{
          to: `/library/${content.featured_project._meta.uid}`,
          text: 'View the project',
        }}
        htmlSerializer={htmlSerializer}
      />
      <AboutBlock
        Link={Link}
        eyebrowProps={{
          color: 'white',
          text: content.about_eyebrow,
        }}
        titleProps={{
          color: 'white',
          text: content.about_header,
        }}
        bodyProps={{
          color: 'white',
          text: content.about_copy[0].text,
        }}
        ctaButtonProps={{
          color: 'white',
          to: '/about',
          text: 'About Power',
        }}
      />
      <CareersBlock
        Link={Link}
        bkgImage={content.careers_background.url}
        image={content.careers_image}
        eyebrowProps={{
          color: 'accent-2',
          text: content.careers_eyebrow,
        }}
        titleProps={{
          color: 'dark-1',
          text: content.careers_header,
        }}
        bodyProps={{
          color: 'dark-1',
          text: content.careers_copy[0].text,
        }}
        ctaButtonProps={{
          to: '/careers',
          text: 'Power Careers',
        }}
      />
      <LibraryBlock
        Link={Link}
        bodyText={content.library_copy[0].text}
        exploreTag={content.library_challenge_text}
        eyebrowText={content.library_eyebrow}
        libraryCards={libraryCards}
        titleText={content.library_header}
      />
      <Ram color="CB361D" />
    </Layout>
  );
}
